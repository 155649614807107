import { rem, SizedBox } from 'ledo-react'
import React, { type FunctionComponent, type PropsWithChildren, type ReactNode, useMemo } from 'react'
import styled from 'styled-components'

import PromotionBanner from '@/components/promotion-banner/PromotionBanner'
import { useGroup } from '@/hooks/useGroup'
import { useUser } from '@/hooks/useUser'
import { useWindow } from '@/hooks/useWindow'
import Footer from '@/ledo-web-content/footer/Footer'
import { maybeGetActiveDistributionPromotion } from '@/utils/promotion'
import { routes } from '@/utils/routes'

interface PageProps {
  hasPadding?: boolean
  leading?: ReactNode
  showFooter?: boolean
}

const Page: FunctionComponent<React.PropsWithChildren<PropsWithChildren<PageProps>>> = ({
  children,
  hasPadding = true,
  showFooter = true,
  leading,
}) => {
  const { height } = useWindow()
  const { user, isCreatingProfile } = useUser()
  const { group } = useGroup()

  const distributionPromotion = useMemo(
    () =>
      maybeGetActiveDistributionPromotion({
        group,
        isCreatingProfile,
        user,
      }),
    [group, user, isCreatingProfile],
  )

  return (
    <PageContainer style={{ minHeight: `${height}px` }}>
      {/* To offset the height of the TopBar. */}
      {hasPadding && <SizedBox height={{ desktop: rem(88), mobile: rem(64) }} />}

      {leading}

      {distributionPromotion && (
        <PromotionBanner
          cta={{ text: 'Create a release', to: routes.products.create.url }}
          icon={distributionPromotion.icon}
          lead={distributionPromotion.lead}
          storage={distributionPromotion.storage}
          uuid={distributionPromotion.uuid}
        >
          <span>{distributionPromotion.description}</span>
        </PromotionBanner>
      )}

      {/* Extra padding until MainContent. */}
      {hasPadding && <SizedBox height={{ desktop: rem(48), mobile: rem(32) }} />}

      <MainContent>{children}</MainContent>

      {hasPadding && <SizedBox height={{ desktop: rem(160), mobile: rem(128) }} />}

      {showFooter && <Footer />}
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MainContent = styled.div`
  flex: 1;
`

export default Page
