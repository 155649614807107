import { type GatsbyLinkProps, Link } from 'gatsby'
import { BaseButton, BrandColors, FontFamily, MediaQueries, Opacities, rem, Transitions } from 'ledo-react'
import React from 'react'
import styled, { css } from 'styled-components'

import type { NavigationItem } from '@/utils/buildNavLinks'

export interface NavigationListItemProps extends Omit<GatsbyLinkProps<HTMLButtonElement>, 'to'> {
  active?: boolean
  direction?: 'column' | 'row'
  featured?: boolean
  linkType?: NavigationItem['type']
  to?: string
}

// Gatsby's link component doesn't allow willy-nilly prop drilling.

const LinkWithFilteredProps = ({ featured, active, activeOn, linkType, ...props }) => <Link to={props.to} {...props} />
const AnchorWithFilteredProps = ({ to, linkType, ...props }) => <a href={to} {...props} />

const getDisplayComponent = ({ to, type }: { to?: string; type: NavigationItem['type'] }) => {
  if (to && type === 'EXTERNAL') return AnchorWithFilteredProps
  if (to) return LinkWithFilteredProps
  return BaseButton
}

const NavigationListItem = styled(BaseButton).attrs<NavigationListItemProps>((props) => ({
  active: props.active ? 1 : 0,
  as: getDisplayComponent({ to: props.to, type: props.linkType }),
  featured: !!props.featured,
}))<NavigationListItemProps>(
  ({ direction = 'column', active = false, disabled = false, featured = false }) => css`
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    transition: background ${Transitions.micro};
    font-family: ${FontFamily.regular};
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: ${rem(20)};
    padding: ${rem(12)} ${rem(20)};
    min-height: ${rem(48)};
    text-decoration: none;

    ${MediaQueries.desktop} {
      font-size: ${rem(18)};
      line-height: ${rem(20)};
      padding: ${rem(12)} ${rem(24)};
    }

    :before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${BrandColors.white.toString()};
      transition: transform ${Transitions.micro};
    }

    :hover {
      background: ${BrandColors.white.alpha(Opacities.eight).toString()};
    }

    :focus,
    :active {
      background: transparent;
    }

    ${featured &&
    css`
      background: ${BrandColors.darkBlue.toString()};

      :hover {
        background: ${BrandColors.darkBlue.darken(Opacities.eight).toString()};
      }

      :focus,
      :active {
        background: ${BrandColors.darkBlue.toString()};
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
      color: ${BrandColors.white.alpha(Opacities.thirtyfive).toString()};
    `}

    ${() => {
      switch (direction) {
        case 'row':
          return css`
            justify-content: center;
            min-width: ${rem(72)};
            height: ${rem(64)};

            ${MediaQueries.desktop} {
              min-width: ${rem(88)};
              height: ${rem(88)};
            }

            :before {
              width: 100%;
              height: ${rem(4)};
              transform: scaleY(0);
              transform-origin: left bottom;
            }

            > span {
              padding: 0.5rem 0;
            }

            :focus,
            :active {
              > span {
                border-bottom: 2px solid ${BrandColors.springGreen.toString()};
              }
            }

            & > * + * {
              margin-left: ${rem(8)};
            }

            ${active &&
            css`
              > span {
                border-bottom: 2px solid ${BrandColors.springGreen.toString()};
              }
            `}
          `
        case 'column':
        default:
          return css`
            text-align: left;
            justify-content: space-between;
            width: 100%;
            min-height: ${rem(48)};

            :before {
              width: ${rem(4)};
              height: 100%;
              transform: scaleX(0);
              transform-origin: left top;
            }

            > span {
              padding: 0.5rem 0;
            }

            ${active &&
            css`
              :before {
                transform: scaleX(1);
                background: ${BrandColors.springGreen.toString()};
              }
            `}
          `
      }
    }}
  `,
)

export default NavigationListItem
