import { navigate } from 'gatsby'
import {
  BrandColors,
  Center,
  Column,
  Flex,
  Icon,
  IconButton,
  type IconData,
  Icons,
  MediaQueries,
  rem,
  Row,
  SecondaryButton,
} from 'ledo-react'
import DesktopOnly from 'ledo-react/src/components/desktop-only/DesktopOnly'
import React, { type FC, type MouseEvent, useEffect, useMemo, useState } from 'react'
import { useCookie, useSessionStorage } from 'react-use'
import styled from 'styled-components'
import { inspect } from 'util'

import { PREFIX } from '@/utils/routes'

export type Storage = 'cookie' | 'sessionStorage' | 'always'

type HidePromotionValue = '0' | '1'
type HidePromotionStorage = [value: HidePromotionValue, store: (value: HidePromotionValue) => void]

export type Props = React.PropsWithChildren<{
  cta: {
    text: string
    to: string
  }
  icon?: IconData
  iconSize?: number
  lead?: string
  storage?: Storage
  uuid?: string
}>

const PromotionBanner: FC<Props> = ({ storage = 'cookie', icon, iconSize = 18, uuid, children, ...props }) => {
  const [open, setOpen] = useState(true)

  const key = useMemo(() => `hide-promotion-banner${uuid ? `-${uuid}` : ''}`, [uuid])
  const storageCookie = useStorageCookie(key)
  const storageSessionStorage = useStorageSessionStorage(key)
  const [hide, storeHide] = useMemo((): HidePromotionStorage => {
    if (storage === 'sessionStorage') {
      return storageSessionStorage
    } else if (storage === 'cookie') {
      return storageCookie
    } else if (storage === 'always') {
      return ['0', () => {}]
    } else {
      assertNever(storage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...storageCookie, ...storageSessionStorage, storage])

  useEffect(() => {
    if (hide === '1') {
      setOpen(false)
    }
  }, [hide, setOpen])

  const onClose = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(false)
    storeHide('1')
  }

  if (!open) {
    return null
  }

  return (
    <Bar mainAxisAlignment="space-between" crossAxisAlignment="center">
      <Center>
        <WarpRow>
          <TextBar>
            <Flex>
              {icon && (
                <Column>
                  <Icon icon={icon} size={rem(iconSize)} />
                </Column>
              )}
              {props.lead && (
                <>
                  <Column>
                    <b>{props.lead}</b>
                  </Column>
                  <DesktopOnly>
                    <Column>—</Column>
                  </DesktopOnly>
                </>
              )}
            </Flex>
            <Column>{children}</Column>
          </TextBar>
          <CTAColumn>
            <CTAButton
              onClick={(e) => {
                onClose(e)
                if (!props.cta.to.startsWith(PREFIX)) {
                  window.location.href = props.cta.to
                } else {
                  void navigate(props.cta.to)
                }
              }}
            >
              {props.cta.text}
            </CTAButton>
          </CTAColumn>
        </WarpRow>
      </Center>
      {storage === 'always' ? null : (
        <Column>
          <CloseButton type="button" onClick={onClose} icon={Icons.cross} />
        </Column>
      )}
    </Bar>
  )
}

export default PromotionBanner

const Bar = styled(Row)`
  background: ${BrandColors.purple.toString()};
  width: 100%;
  min-height: ${rem(88)};
  padding ${rem(8)} ${rem(16)};
`

const TextBar = styled(Row)`
  align-items: center;
  flex-direction: column;

  ${Flex} {
    gap: ${rem(10)};
  }

  ${MediaQueries.desktop} {
    flex-direction: row;
    gap: ${rem(10)};
  }
`

const WarpRow = styled(Row)`
  gap: ${rem(10)};
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
`

const CTAButton = styled(SecondaryButton)`
  padding: ${rem(8)};
  height: ${rem(32)};
  white-space: nowrap;
  font-size: ${rem(16)};
`

const CTAColumn = styled(Column)`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

const CloseButton = styled(IconButton)`
  font-size: ${rem(24)};
`

const useStorageCookie = (key: string): HidePromotionStorage => {
  const [hide, storeHide] = useCookie(key)

  return [hide === '1' ? '1' : '0', storeHide]
}

const useStorageSessionStorage = (key: string): HidePromotionStorage => {
  const [hide, storeHide] = useSessionStorage<'0' | '1'>(key, '0')

  return [hide === '1' ? '1' : '0', storeHide]
}

function assertNever(value: unknown): asserts value is never {
  throw new Error(`Reached a value ${inspect(value)} that should never be reached.`)
}
