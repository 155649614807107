import { navigate } from 'gatsby'
import { SecondaryButton, SignOutIcon } from 'ledo-react'
import React, { type FunctionComponent, useCallback } from 'react'

import { routes } from '../../../utils/routes'

const SignOutButton: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const handleSignOut = useCallback(() => {
    void navigate(routes.logout.url)
  }, [])

  return (
    <SecondaryButton onClick={handleSignOut} size="small" trailing={<SignOutIcon />} style={{ width: '100%' }}>
      Sign Out
    </SecondaryButton>
  )
}

export default SignOutButton
