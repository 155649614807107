import React, { type FunctionComponent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import placeholder from '../../assets/images/avatarPlaceholder.svg'
import { MediaQueries } from '../../tokens/mediaQueries'
import { rem } from '../../utils/rem'
import Image from '../image/Image'

interface ResponsiveSize {
  desktop?: string | number
  mobile?: string | number
}

export type AvatarSize = string | number | ResponsiveSize
export type AvatarShape = 'round' | 'square'

export interface AvatarProps {
  fallback?: string
  shape?: AvatarShape
  size?: AvatarSize
  src?: string
}

const Avatar: FunctionComponent<React.PropsWithChildren<AvatarProps>> = ({
  src,
  size = { desktop: rem(96), mobile: rem(80) },
  shape = 'round',
  fallback = placeholder,
}) => {
  const [imgSrc, setImgSrc] = useState(src)

  const handleError = () => {
    setImgSrc(fallback)
  }

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <Mask size={size} shape={shape}>
      <Image src={imgSrc} fallback={fallback} onError={handleError} width="100%" height="100%" loading={'lazy'} />
    </Mask>
  )
}

export default Avatar

const Mask = styled.div<AvatarProps>(({ shape, ...props }) => {
  let size: ResponsiveSize

  switch (typeof props.size) {
    case 'object':
      size = props.size
      break

    case 'string':
      size = {
        desktop: props.size,
        mobile: props.size,
      }
      break

    case 'number':
    default:
      size = {
        desktop: `${props.size}px`,
        mobile: `${props.size}px`,
      }
      break
  }

  return css`
    position: relative;
    display: block;
    border-radius: ${shape === 'round' ? '50%' : 'none'};
    overflow: hidden;
    flex-shrink: 0;
    width: ${size.mobile};
    height: ${size.mobile};

    ${MediaQueries.desktop} {
      width: ${size.desktop};
      height: ${size.desktop};
    }
  `
})
