import { Amplify, Auth, Hub } from 'aws-amplify'
import { type GatsbyBrowser } from 'gatsby'
import React from 'react'
import TagManager from 'react-gtm-module'
import { hubEventsListener, generateAwsRumClient } from 'ledo-react'

import Layout from './src/components/layout/Layout'

const awsRumClient = generateAwsRumClient({
  applicationId: process.env.AWS_RUM_APPLICATION_ID ?? '',
  applicationVersion: process.env.AWS_RUM_APPLICATION_VERSION ?? '',
  awsRegion: process.env.AWS_REGION ?? '',
  enabled: Boolean(process.env.AWS_RUM_ENABLED),
  endpoint: process.env.AWS_RUM_ENDPOINT,
  identityPoolId: process.env.AWS_RUM_IDENTITY_POOL_ID,
})

Amplify.configure({
  // Cognito
  Auth: {
    identityPoolId: process.env.AWS_AUTH_IDENTITY_POOL_ID,
    userPoolId: process.env.AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_AUTH_USER_POOL_CLIENT_ID,
  },

  // S3
  Storage: {
    AWSS3: {
      bucket: process.env.AWS_S3_BUCKET,
      region: process.env.AWS_REGION,
    },
  },

  // AppSync
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: process.env.API_URL,
  aws_appsync_region: process.env.AWS_REGION,

  graphql_headers: async () => {
    const session = await Auth.currentSession()
    return {
      Authorization: session.getIdToken().getJwtToken(),
    }
  },

  // General
  region: process.env.AWS_REGION,
})

Hub.listen('auth', (i) => void hubEventsListener(i))

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  const groupStorage = typeof window !== 'undefined' ? localStorage.getItem('selectedGroupRoles') : undefined
  if (groupStorage) {
    const roles = JSON.parse(groupStorage)
    if (roles && roles.length > 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'navigation',
          navigation: { current: location, prev: prevLocation },
          roles: roles,
        },
      })
    }
    awsRumClient.recordEvent('navigation', { current: location, prev: prevLocation })
  }
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => (
  <Layout layout={(props.pageContext.layout as string) ?? undefined}>{element}</Layout>
)
