import React, { type FunctionComponent } from 'react'

import Anchor, { type AnchorProps } from '../anchor/Anchor'

export interface ExternalLinkProps extends Omit<AnchorProps, 'href'> {
  blank?: boolean
  to: string
}

const ExternalLink: FunctionComponent<React.PropsWithChildren<ExternalLinkProps>> = ({ to, blank, ...props }) => (
  <Anchor
    rel="noopener noreferrer"
    {...{ ...props, ...(blank !== false ? { target: '_blank' } : undefined) }}
    href={to}
  />
)

export default ExternalLink
