import React, { type FunctionComponent } from 'react'

import ExternalLink, { type ExternalLinkProps } from '../external-link/ExternalLink'
import InternalLink, { type InternalLinkProps } from '../internal-link/InternalLink'

export type InternalOrExternalLinkProps =
  | ({ external: true } & ExternalLinkProps)
  | ({ external?: false } & InternalLinkProps)

const InternalOrExternalLink: FunctionComponent<InternalOrExternalLinkProps> = (props) => {
  if ('external' in props && props.external) {
    const { external, ...rest } = props

    return <ExternalLink blank={false} rel={undefined} {...rest} />
  } else {
    const { external, ...rest } = props

    return <InternalLink {...rest} />
  }
}

export default InternalOrExternalLink
