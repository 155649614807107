import { Link, navigate } from 'gatsby'
import {
  Avatar,
  Body,
  BrandColors,
  Column,
  Icon,
  Icons,
  MediaQueries,
  Opacities,
  Padding,
  rem,
  Row,
  SecondaryButton,
  SizedBox,
  SyncIcon,
  Text,
  Transitions,
} from 'ledo-react'
import React, { type Dispatch, forwardRef, useContext } from 'react'
import styled, { css } from 'styled-components'

import { groupFormSteppedContext } from '@/components/group-form-stepped/GroupFormSteppedProvider'
import SignOutButton from '@/components/top-bar/sign-out-button/SignOutButton'
import { useGroup } from '@/hooks/useGroup'
import { useUser } from '@/hooks/useUser'
import { type Group } from '@/providers/GroupProvider'
import { getImageSize, ImageSize } from '@/utils/imageSize'
import { routes } from '@/utils/routes'

interface DropdownProps {
  closeDropdown: () => void
  groups: Pick<Group, 'avatar' | 'id' | 'name'>[]
  isMobile: boolean
  open: boolean
  selectGroup: Dispatch<string>
  selectedGroup: Group
  setShowTeams: (boolean) => void
  showTeams: boolean
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ closeDropdown, groups, open, selectedGroup, selectGroup, showTeams, setShowTeams }, ref) => {
    const { user, userProfile } = useUser()
    const { email, impersonating } = user.getSignInUserSession().getIdToken().payload
    const { group: currentGroup } = useGroup()

    const sortGroups = groups?.sort((firstGroup, secondGroup) => {
      if (firstGroup.id === secondGroup.id) return 0
      if (firstGroup.id === currentGroup?.id) return -1
      if (secondGroup.id === currentGroup?.id) return 1

      if (firstGroup.name < secondGroup.name) return -1
      if (firstGroup.name > secondGroup.name) return 1
      return 0
    })

    const { resetSteps } = useContext(groupFormSteppedContext)

    return (
      <StyledDropdown ref={ref} open={open}>
        {!showTeams ? (
          <>
            <GroupWrapper padding={rem(16)} bottom={rem(8)}>
              <Row crossAxisAlignment="center">
                <Avatar src={getImageSize(currentGroup?.avatar, ImageSize.small)?.url} size={24} />
                {currentGroup && (
                  <>
                    <SizedBox width={rem(12)} />
                    <TeamName size="medium" color={BrandColors.white.alpha(Opacities.seventyfive).toString()}>
                      {currentGroup.name}
                    </TeamName>
                  </>
                )}
              </Row>
              <Padding horizontal={rem(32)} top={rem(16)} bottom={rem(24)}>
                <List>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-team-royalties"
                      onClick={closeDropdown}
                      to={routes.royalties.group.url}
                    >
                      Royalties
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-team-royalties"
                      onClick={closeDropdown}
                      to={routes.invoices.group.url}
                    >
                      Invoices
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-team-settings"
                      onClick={closeDropdown}
                      to={routes.group.overview.url}
                    >
                      Settings
                    </StyledLink>
                  </ListItem>
                </List>
              </Padding>
              <SecondaryButton
                size="small"
                trailing={<SyncIcon />}
                style={{ width: '100%' }}
                onClick={() => {
                  setShowTeams(true)
                }}
                className="switchTeamsButton"
              >
                Switch profile
              </SecondaryButton>
              <SizedBox height={rem(16)} />
            </GroupWrapper>
            <UserWrapper padding={rem(16)} bottom={rem(8)}>
              <Row crossAxisAlignment="center">
                <Avatar src={getImageSize(userProfile?.avatar, ImageSize.small)?.url} size={24} />
                {userProfile && (
                  <>
                    <SizedBox width={rem(12)} />
                    <TeamName size="medium" color={BrandColors.white.alpha(Opacities.seventyfive).toString()}>
                      {userProfile.name}
                    </TeamName>
                  </>
                )}
              </Row>
              <Padding horizontal={rem(32)} top={rem(16)} bottom={rem(24)}>
                <List>
                  <ListItem>
                    <LoggedInAs label="Logged in as" email={userProfile?.email} />
                    {impersonating && <LoggedInAs label="Impersonated by admin" email={email} />}
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-user-royalties"
                      onClick={closeDropdown}
                      to={routes.royalties.account.url}
                    >
                      My Royalties
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-user-royalties"
                      onClick={closeDropdown}
                      to={routes.invoices.account.url}
                    >
                      My Invoices
                    </StyledLink>
                  </ListItem>
                  <ListItem>
                    <StyledLink
                      data-gtm-name="main-navigation-team-settings"
                      onClick={closeDropdown}
                      to={routes.account.overview.url}
                    >
                      My Settings
                    </StyledLink>
                  </ListItem>
                </List>
              </Padding>
              <SignOutButton />
              <SizedBox height={rem(16)} />
            </UserWrapper>
          </>
        ) : (
          <SwitchTeamsWrapper top={rem(8)} bottom={rem(16)}>
            {sortGroups.map(({ avatar, id, name }) => (
              <TeamLink
                data-gtm-name="main-navigation-select-group"
                data-gtm-value={id}
                isActive={id === selectedGroup?.id}
                key={id}
                onClick={() => {
                  selectGroup(id)
                  closeDropdown()
                }}
                to={routes.home.url}
              >
                <Row crossAxisAlignment="center">
                  <Avatar src={getImageSize(avatar, ImageSize.small)?.url} size={24} />
                  {name && (
                    <>
                      <SizedBox width={rem(12)} />
                      <TeamName size="medium" color={BrandColors.white.alpha(Opacities.seventyfive).toString()}>
                        {name}
                      </TeamName>
                    </>
                  )}
                </Row>
              </TeamLink>
            ))}
            <CreateNewProfileButton
              size="small"
              trailing={<Icon icon={Icons.plus} />}
              className="switchTeamsButton"
              onClick={() => {
                resetSteps()
                closeDropdown()
                void navigate(routes.createGroup.url)
              }}
            >
              Create a new profile
            </CreateNewProfileButton>
          </SwitchTeamsWrapper>
        )}
      </StyledDropdown>
    )
  },
)

Dropdown.displayName = 'Dropdown'

export default Dropdown

interface StyledDropdownProps {
  open: boolean
}

const StyledDropdown = styled.div<StyledDropdownProps>`
  position: fixed;
  width: ${rem(272)};
  height: auto;
  overflow-y: auto;
  top: ${rem(64)};
  right: 0;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  min-height: 100%;
  max-height: none;
  z-index: 3;

  ${MediaQueries.desktop} {
    top: ${rem(88)};
    min-height: unset;
    max-height: 70vh;
  }
`

const TeamName = styled(Body)`
  font-weight: 400;
  font-size: ${rem(18)};
`

const ListItem = styled.li`
  list-style: '';
`

const List = styled.ul`
  margin: 0;
`

const GroupWrapper = styled(Padding)`
  background: ${BrandColors.steelGray.toString()};
`

const UserWrapper = styled(Padding)`
  background: ${BrandColors.darkBlue.toString()};

  flex: 1;
`

const SwitchTeamsWrapper = styled(Padding)`
  background: ${BrandColors.steelGray.toString()};
  overflow-x: scroll;
  position: relative;
  max-height: calc(100vh - 4rem);

  ${MediaQueries.desktop} {
    max-height: ${rem(480)};
  }
`

const StyledLink = styled(Link)`
  margin-bottom: ${rem(8)};
  text-decoration: none;
  color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  display: block;
  font-weight: 400;

  ${MediaQueries.desktop} {
    line-height: ${rem(28)};
  }

  &:hover {
    color: ${BrandColors.white.toString()};
    transition: color 0.5s ease-in-out;
  }
`

const TeamLink = styled(Link)<{ isActive: boolean }>`
  text-decoration: none;
  color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  display: block;
  font-weight: 400;
  padding: ${rem(8)} ${rem(14)};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: ${rem(8)};
    left: 0;
    background: ${BrandColors.springGreen.toString()};
    transition: transform ${Transitions.micro};
    width: ${rem(2)};
    height: calc(100% - ${rem(14)});
    transform: scaleX(0);
    transform-origin: left top;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        transform: scaleX(1);
      }
    `}

  &:hover {
    &:before {
      transform: scaleX(1);
    }
  }
`

const CreateNewProfileButton = styled(SecondaryButton)`
  position: sticky;
  left: ${rem(16)};
  right: ${rem(16)};
  margin-top: ${rem(16)};
  bottom: 0;
  background-color: ${BrandColors.steelGray.toString()};
  width: calc(100% - ${rem(32)});
  &:hover,
  &:focus {
    background-color: ${BrandColors.haiti.toString()};
  }
`

const LoggedInAsEmail = styled(Text).attrs({ block: true })`
  margin-bottom: ${rem(8)};
  text-decoration: none;
  color: ${BrandColors.white.alpha(Opacities.seventyfive).toString()};
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  font-weight: 400;

  ${MediaQueries.desktop} {
    line-height: ${rem(28)};
  }
`

const LoggedInAs = ({ label, email }: { email?: string; label: string }) => {
  if (!email) return null

  return (
    <Column>
      <Text uppercase block fontSize={rem(12)}>
        {label}:
      </Text>
      <LoggedInAsEmail>{email}</LoggedInAsEmail>
    </Column>
  )
}
